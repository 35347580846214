import React, { useState } from "react";
import axios from "axios";
import { FaFacebook, FaInstagram, FaWhatsapp, FaYoutube } from "react-icons/fa";

import {
  ContactContainer,
  ContactWrapper,
  ContactRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  FormWrap,
  FormContent,
  Form,
  FormLabel,
  PhoneNumberFormInputError,
  PhoneNumberFormInput,
  FormInput,
  FormInputTextArea,
  FormButton,
  SocialIcons,
  SocialIconLink,
  ContactMessage,
} from "./ContactElements";

const Contact = ({ lightBg, id, imgStart, lightText, headline, darkText }) => {
  const API_PATH = "/api/contact.php";

  //Form states
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phoneErrorMessage, setPhoneErrorMessage] = useState("");
  const [formStatus, setFormStatus] = useState(false);

  const onContactHandler = (e) => {
    e.preventDefault();

    var numbers = /^[+27]?[0-9]+$/;

    if (phone.includes("+27")) {
      let cleanNumber = phone.replace("+27", "0");
      setPhone(cleanNumber);
      setPhoneErrorMessage("");
    }

    if (phone.length !== 10 || !phone.match(numbers)) {
      setPhoneErrorMessage("Incorrect number format");
    } else {
      setPhoneErrorMessage("");
    }

    if (phoneErrorMessage === "") {
      axios({
        method: "post",
        url: `${API_PATH}`,
        headers: { "content-type": "application/json" },
        data: {
          name: name,
          phone: phone,
          email: email,
          message: message,
        },
      })
        .then((result) => {
          setFormStatus(result.data.sent);
          setName("");
          setEmail("");
          setPhone("");
          setMessage("");

          setTimeout(() => {
            setFormStatus(false);
          }, 5000);
        })
        .catch((error) => console.log(error.message));
    }
  };

  return (
    <ContactContainer lightBg={lightBg} id={id}>
      <ContactWrapper>
        <ContactRow imgStart={imgStart}>
          <Column1>
            <TextWrapper>
              <TopLine>Let's Get In Touch</TopLine>
              <Subtitle darkText={darkText}>
                Cell: +27 65 954 0435 <br />
                Email: info@rebatho.org
              </Subtitle>
              <TopLine>Follow Us</TopLine>
              <SocialIcons>
                <SocialIconLink
                  href="https://www.facebook.com/Rebathoagainsthumantrafficking"
                  target="_blank"
                  arial-label="Facebook"
                >
                  <FaFacebook />
                </SocialIconLink>
                <SocialIconLink
                  href="https://www.instagram.com/rebatho_organisation/?utm_medium=copy_link"
                  target="_blank"
                  arial-label="WhatsApp"
                  rel="noopener noreferrer"
                >
                  <FaInstagram />
                </SocialIconLink>
                <SocialIconLink
                  href="https://www.youtube.com/channel/UCZ3LP7rr5lLbV5tvoJXj6Ew"
                  target="_blank"
                  arial-label="WhatsApp"
                  rel="noopener noreferrer"
                >
                  <FaYoutube />
                </SocialIconLink>
              </SocialIcons>
            </TextWrapper>
          </Column1>
          <Column2>
            <FormWrap>
              <FormContent>
                <Form onSubmit={onContactHandler}>
                  <Heading lightText={lightText}>{headline}</Heading>
                  <ContactMessage messageSent={formStatus}>
                    Thank you for sending us a message. We will be getting in
                    touch soon!.
                  </ContactMessage>
                  <FormLabel htmlFor="for">Name</FormLabel>
                  <FormInput
                    type="name"
                    value={name}
                    name="name"
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <FormLabel htmlFor="for">Phone Number</FormLabel>
                  <PhoneNumberFormInput
                    type="text"
                    value={phone}
                    name="phone"
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                  {phoneErrorMessage && (
                    <PhoneNumberFormInputError>
                      {phoneErrorMessage}
                    </PhoneNumberFormInputError>
                  )}
                  <FormLabel htmlFor="for">Email</FormLabel>
                  <FormInput
                    type="email"
                    value={email}
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <FormLabel htmlFor="for">Message</FormLabel>
                  <FormInputTextArea
                    type="text"
                    value={message}
                    name="message"
                    onChange={(e) => setMessage(e.target.value)}
                    required
                  />
                  <FormButton type="submit">Send Message</FormButton>
                </Form>
              </FormContent>
            </FormWrap>
          </Column2>
        </ContactRow>
      </ContactWrapper>
    </ContactContainer>
  );
};

export default Contact;
