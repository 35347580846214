import React, { useState } from "react";

import { Button } from "../ButtonElement";

import RebathoCoverImage from "../../images/Rebatho_cover.jpg";
import volunteer from "../../images/volunteer.jpg";

import Carousel from "re-carousel";

import {
  HeroContainer,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
  ImgWrap,
  ImgBg,
} from "./HeroElement";

const HeroSection = ({ img, alt }) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };
  return (
    <>
      <HeroContainer id="home">
        <Carousel loop auto={true} duration={300}>
          <div>
            <ImgWrap>
              <ImgBg src={RebathoCoverImage} />
            </ImgWrap>
            <HeroContent></HeroContent>
          </div>
          <ImgWrap>
            <HeroContent>
              <HeroH1>Hiking Against Modern Day Slavery.</HeroH1>
              <HeroP>
                Rebatho would like to thank all our supporters for partnering
                with us for the second annual Fund raising hike. Some of you
                braved the kilometers with us and others in absentia, gave
                generously to make the event a success. We love you and we
                appreciate you. A heartfelt thank you to you all!
              </HeroP>
              <HeroBtnWrapper>
                <Button
                  to="about"
                  onMouseEnter={onHover}
                  onMouseLeave={onHover}
                  primary="true"
                  dark="true"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Learn More {hover ? <ArrowForward /> : <ArrowRight />}
                </Button>
              </HeroBtnWrapper>
            </HeroContent>
          </ImgWrap>
          <div>
            <ImgWrap>
              <ImgBg src={volunteer} />
            </ImgWrap>
            <HeroContent>
              <HeroH1>Be Involved</HeroH1>
              <HeroP>
                Support the organization with resources, these may be money or
                any other material resource that will assist. Please see our Be
                inloved Page
              </HeroP>
              <HeroBtnWrapper>
                <Button
                  to="pricing"
                  onMouseEnter={onHover}
                  onMouseLeave={onHover}
                  primary="true"
                  dark="true"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  Learn More {hover ? <ArrowForward /> : <ArrowRight />}
                </Button>
              </HeroBtnWrapper>
            </HeroContent>
          </div>
        </Carousel>
      </HeroContainer>
    </>
  );
};

export default HeroSection;
