import { 
    VOLUNTEER_REQUEST,
    VOLUNTEER_SUCCESS,
    VOLUNTEER_FAIL,
 } from '../constants/volunteerConstants'

 //Promise based HTTP client
import axios from 'axios'

//  export const addToCart = (product) => async (dispatch, getState) => {

 export const volunteer = (name, phone,  email, age, occupation, description) => async (dispatch, getState) => {

    const API_PATH = '/api/volunteer.php';

    try {
        dispatch({type: VOLUNTEER_REQUEST})

        //HTTP CONFIG
        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        //API REQUEST
        const { data } = await axios.post(
            API_PATH,
            {
                'name': name ,
                'phone': phone,
                'email': email, 
                'age': age, 
                'occupation': occupation, 
                'description': description
            },
            config
        )
        dispatch({
            type: VOLUNTEER_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: VOLUNTEER_FAIL,
            payload: error.response && error.response.data.message
                ? 'Error while donating'
                : 'Incorrect details',
        })
    }

 }