import React from "react";
import ScrollTopTop from "../components/ScrollToTop";

import SingIn from "../components/Signin";

const SigninPage = () => {
  return (
    <>
      <ScrollTopTop />
      <SingIn />
    </>
  );
};

export default SigninPage;
