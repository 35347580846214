import styled from 'styled-components'

import { MdArrowForward, MdKeyboardArrowRight } from 'react-icons/md'

export const HeroContainer = styled.div`
    /* background: #581845; */
    background: linear-gradient(to bottom,rgba(88, 24, 69, 1),rgba(253,216,53,.01));

    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 100px 30px; */
    height: 860px;
    position: relative;
    z-index: 2;

    :before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background: linear-gradient (180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%),
        linear-gradient (180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
    }

    @media screen and (max-width: 768px) {
        /* font-size: 40px; */
    }

    @media screen and (max-width: 480px) {
        /* height: 60vh; */
    }
`

export const HeroBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`

export const Top = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
`

export const TopBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`

export const VideoBg = styled.video`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`

export const HeroContent = styled.div`
    z-index: 3;
    background: linear-gradient(to bottom,rgba(88, 24, 69, 1),rgba(253,216,53,.01));
    /* max-width: 1200px; */
    width: 100%;
    height: 100%;
    position: absolute;
    /* top: 5rem; */
    padding-top: 10rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const HeroH1 = styled.h1`
    color: #F9F9F9;
    font-size: 48px;
    text-align: center;
    font-family: 'Architects Daughter', cursive;

    @media screen and (max-width: 768px) {
        font-size: 40px;
    }

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`

export const HeroP = styled.p`
    margin-top: 24px;
    color: #F9F9F9;
    font-size: 24px;
    text-align: center;
    max-width: 600px;

    @media screen and (max-width: 768px) {
        font-size: 24px;
    }

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }
`

export const HeroBtnWrapper = styled.div`
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const ArrowForward = styled(MdArrowForward)`
    margin-left: 8px;
    font-size: 20px;
`

export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left: 8px;
    font-size: 20px;
`

export const ImgWrap = styled.div`
    /* max-width: 555px; */
    /* height: 860px; */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: linear-gradient(to bottom,rgba(88, 24, 69, 1),rgba(253,216,53,.01));
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;
  height: 860px;

  @media screen and (max-width: 768px) {
    width: 100%;
    }

    @media screen and (max-width: 480px) {
        width: 100%;
    }
`;


export const ImgBg = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    
    @media screen and (max-width: 768px) {
        height: 80%;
    }

    @media screen and (max-width: 480px) {
        height: 30%;
    }
`