import {
    DONATE_REQUEST,
    DONATE_SUCCESS,
    DONATE_FAIL,
} from '../constants/donateConstants';


export const donateReducer = (state = {}, action) => {
    switch (action.type) {
        case DONATE_REQUEST:
            return { loading: true}
        case DONATE_SUCCESS:
            return { loading: false, donate: action.payload}
        case DONATE_FAIL:
            return { loading: false, error: action.payload }    
        default:
            return state
    }
}