import styled from "styled-components";
import { FaMagento } from 'react-icons/fa'
import { Link } from "react-router-dom";
import { Link as LinkS } from "react-scroll";
import { Link as LinkR } from "react-router-dom";

export const FooterContainer = styled.footer`
  background-color: #CCB23A;
`;

export const FooterWrap = styled.div`
  padding: 48px 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1100px;
  margin: 0 auto;
`;

export const FooterLinksContainer = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 820px) {
    padding-top: 32px;
  }
`;

export const FooterLinksWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 820px) {
    flex-direction: column;
  }
`;

export const FooterLinkItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* margin: 16px 10px 0; */
  text-align: left;
  width: 250px;
  box-sizing: border-box;
  color: #F9F9F9;

  @media screen and (max-width: 820px) {
    margin: 0;
    padding: 10px;
    width: 100%;
  }
`;

export const FooterLinkTitle = styled.h1`
  font-size: 14px;
  margin-bottom: 16px;
`;

export const FooterLink = styled(Link)`
  color: #F9F9F9;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;

  &:hover {
    color: #3871ff;
    transition: 0.3s ease-out;
  }
`;

export const FooterAddressLink = styled(Link)`
  color: #F9F9F9;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;
  cursor: auto;

  &:hover {
    color: #3871ff;
    transition: 0.3s ease-out;
  }
`;

export const SocialMedia = styled.section`
  max-width: 1000px;
  width: 100%;
`;

export const PoweredBy = styled.section ` 
  max-width: 1000px;
  width: 100%;
`

export const SocialIcon = styled(FaMagento)`
    margin-right: 10px;
    font-size: 35px;
    transform: rotate(270deg);
`

export const PoweredByWrap = styled.div ` 
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1000px;
  margin: 0;

  @media screen and (max-width: 820px) {
      flex-direction: column;
  }
`

export const SocialMediaWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1000px;
    margin: 40px auto 0 auto;

    @media screen and (max-width: 820px) {
        flex-direction: column;
    }
`;

export const SocialLogo = styled.div`
  color: #F9F9F9;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-weight: bold;
`;

export const WebsiteRights = styled.small`
  color: #F9F9F9;
  font-size: 0.9rem;
`;

export const SocialIcons = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 240px;
`;

export const SocialIconLink = styled.a`
  color: #F9F9F9;
  font-size: 24px;
  padding: 0 15px 0 15px;
`;

export const FooterLinks = styled(LinkS)`
  color: #F9F9F9;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-size: 14px;
  cursor: pointer;

  &:hover {
    color: #3871ff;
    transition: 0.3s ease-out;
  }
`;

export const Img = styled.img`
  margin: 0;
`;

export const FooterLogoText = styled.p  `
  position: relative;
  right: 18px;

  @media screen and (max-width: 768px) {
    position: relative;
    right: 18px;
    /* padding-top: 0.7rem; */
  }
`

export const ImgFooter = styled.img `
  width: 100%;
`

export const FooterLogo = styled(LinkR)`
  color: #F9F9F9;
  justify-self: flex-start;
  /* font-size: 1.5rem; */
  cursor: pointer;
  display: flex;
  align-center: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;
  max-width: 200px;

  @media screen and (max-width: 768px) {
    padding: 0;
    margin-left: 0;
    margin-top: 1.5rem;
  }
`;