import { 
    DONATE_REQUEST,
    DONATE_SUCCESS,
    DONATE_FAIL,
 } from '../constants/donateConstants'

 //Promise based HTTP client
import axios from 'axios'

//  export const addToCart = (product) => async (dispatch, getState) => {

 export const donate = (name, phone,  email, donation) => async (dispatch, getState) => {

    const API_PATH = '/api/donate.php';

    try {
        dispatch({type: DONATE_REQUEST})

        //HTTP CONFIG
        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        //API REQUEST
        const { data } = await axios.post(
            API_PATH,
            {'name': name , 'phone': phone, 'email': email, 'donation': donation},
            config
        )

        dispatch({
            type: DONATE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: DONATE_FAIL,
            payload: error.response && error.response.data.message
                ? 'Error while donating'
                : 'Incorrect details',
        })
    }

 }