import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useHistory } from "react-router-dom";

import { useAlert } from "react-alert";

import { PaystackButton } from "react-paystack";

import {
  Container,
  FormWrap,
  FormContent,
  Form,
  FormH1,
  FormLabel,
  FormInput,
  TopLine,
  FormButton,
  PhoneNumberFormInputError,
} from "./DonateElements";

import { donate } from "../../actions/donateAction";

function Donate() {
  let history = useHistory();

  const alert = useAlert();

  const donateData = useSelector((state) => state.donate);

  const { donate: donationInfo } = donateData;
  //states
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [donation, setDonation] = useState("");
  const [message, setMessage] = useState("");
  const [paymentData, setPaymentData] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    if (email !== "" && donation !== "") {
      let amount = parseInt(donation);
      const data = {
        reference: new Date().getTime().toString(),
        email: email,
        amount: amount * 100,
        currency: "ZAR",
        publicKey: "pk_live_68f249f7c1c98cb6b7ba54cf6e0cbb976baf6ecc",
      };
      setPaymentData(data);
    }
  }, [alert, history, email, donation, donationInfo]);

  // you can call this function anything
  const handlePaystackSuccessAction = (reference) => {
    if (reference) {
      dispatch(donate(name, phone, email, donation));
      alert.success("Thank you for donating!...");
      setName("");
      setPhone("");
      setEmail("");
      setDonation("");
      setPaymentData({});
    }
  };

  // you can call this function anything
  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log("closed");
  };

  const componentProps = {
    ...paymentData,
    text: "Donate",
    onSuccess: (reference) => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    var numbers = /^[+27]?[0-9]+$/;

    if (phone.includes("+27")) {
      let cleanNumber = phone.replace("+27", "0");
      setPhone(cleanNumber);
      setMessage("");
    }

    if (phone.length !== 10 || !phone.match(numbers)) {
      setMessage("Incorrect number format");
    } else {
      setMessage("");
    }

    if (isNaN(phone)) {
      setMessage("Incorrect Phone Number. Please enter the correct numbers");
    }
  };

  return (
    <>
      <Container>
        <FormWrap>
          <FormContent>
            <Form onSubmit={handleSubmit}>
              <TopLine>Your help is needed</TopLine>
              <FormH1>Donate</FormH1>
              <FormLabel htmlFor="for">Name</FormLabel>
              <FormInput
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <FormLabel htmlFor="for">Phone Number</FormLabel>
              <FormInput
                type="text"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
              {message && (
                <PhoneNumberFormInputError>{message}</PhoneNumberFormInputError>
              )}
              <FormLabel htmlFor="for">Email</FormLabel>
              <FormInput
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <FormLabel htmlFor="for">Donation</FormLabel>
              <FormInput
                type="number"
                name="donation"
                value={donation}
                onChange={(e) => setDonation(e.target.value)}
                required
              />

              {email && donation ? (
                <PaystackButton
                  type="submit"
                  className="payment-button"
                  {...componentProps}
                />
              ) : (
                <FormButton type="submit">Donate</FormButton>
              )}
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  );
}

export default Donate;
