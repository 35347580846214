import React from "react";

import { animateScroll as scroll } from "react-scroll";

import img from "../../images/rebatho.png";

import { FaFacebook, FaYoutube, FaInstagram } from "react-icons/fa";

import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
  FooterAddressLink,
  FooterLogo,
  ImgFooter,
  PoweredBy,
  PoweredByWrap,
} from "./FooterElements";

const Footer = () => {
  const toggleHome = () => {
    scroll.scrollToTop();
  };
  return (
    <>
      <FooterContainer>
        <FooterWrap>
          <FooterLinksContainer>
            <FooterLinksWrapper></FooterLinksWrapper>
            <FooterLinksWrapper>
              <FooterLinkItems>
                <FooterLinkTitle>Address</FooterLinkTitle>
                <FooterAddressLink to="signin">
                  25 Highfern Avenue
                </FooterAddressLink>
                <FooterAddressLink to="signin">Centurion</FooterAddressLink>
                <FooterAddressLink to="signin">Pretoria</FooterAddressLink>
                <FooterAddressLink to="signin">0157</FooterAddressLink>
              </FooterLinkItems>
              <FooterLinkItems>
                <FooterLinkTitle>Contact us</FooterLinkTitle>
                <FooterLink to="signin">Cell: +27 65 954 0435</FooterLink>
                <FooterLink to="signin">Email: info@rebatho.org</FooterLink>
              </FooterLinkItems>
            </FooterLinksWrapper>
          </FooterLinksContainer>
          <SocialMedia>
            <SocialMediaWrap>
              <FooterLogo to="/" onClick={toggleHome}>
                <ImgFooter src={img} alt={"logo"} />
              </FooterLogo>
              <WebsiteRights>
                rebatho &copy; 2021 - NPO reg:212-410 | NPC 20191588316108
              </WebsiteRights>
              <SocialIcons>
                <SocialIconLink
                  href="https://www.facebook.com/Rebathoagainsthumantrafficking"
                  target="_blank"
                  arial-label="Facebook"
                >
                  <FaFacebook />
                </SocialIconLink>
                <SocialIconLink
                  href={
                    "https://www.instagram.com/rebatho_organisation/?utm_medium=copy_link"
                  }
                  target="_blank"
                  arial-label="Instagram"
                  rel="noopener noreferrer"
                >
                  <FaInstagram />
                </SocialIconLink>
                <SocialIconLink
                  href={
                    "https://www.youtube.com/channel/UCZ3LP7rr5lLbV5tvoJXj6Ew"
                  }
                  target="_blank"
                  arial-label="Youtube"
                  rel="noopener noreferrer"
                >
                  <FaYoutube />
                </SocialIconLink>
              </SocialIcons>
            </SocialMediaWrap>
          </SocialMedia>
          <PoweredBy>
            <PoweredByWrap>
              <WebsiteRights>created by clickideas</WebsiteRights>
            </PoweredByWrap>
          </PoweredBy>
        </FooterWrap>
      </FooterContainer>
    </>
  );
};

export default Footer;
