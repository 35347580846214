import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import HeroSection from "../components/HeroSection";
import Options from '../components/Options/Options';
import Contact from '../components/Contact/Contact';
import AboutSection from '../components/AboutSection';
import {
  homeAboutObj,
  homeObjContact,
  homeCoverObj
} from "../components/InfoSection/Data";

import Services from "../components/Services";
import Footer from "../components/Footer";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const menuItems = [{
    id: 'about',
    title: 'About',
  },{
    id: 'services',
    title: 'Services',
  },{
    id: 'pricing',
    title: 'Get Involved',
  },{
    id: 'contactus',
    title: 'Contact',
  }]

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} home={true} />
      <Navbar toggle={toggle} menu={menuItems} home={true} />
      <HeroSection {...homeCoverObj}/>
      <AboutSection {...homeAboutObj} />
      <Services />
      <Options />
      <Contact {...homeObjContact} />
      <Footer />
    </>
  );
};

export default Home;
