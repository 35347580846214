import React from "react";

import { Button } from "../ButtonElement";

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  TextWrapper,
  Heading,
  Subtitle,
  BtnWrap,
} from "./InfoElements";

const InfoSection = ({
  lightBg,
  id,
  imgStart,
  lightText,
  headline,
  darkText,
  buttonLabel,
  dark,
  primary,
}) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
              <TextWrapper>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle darkText={darkText}>An organisation established to abolish, furthermore positively and effectively rule over modern day slavery, this vile that poses an infringement of basic human rights, stripping away human dignity therefore instilling fear on victims and families and those vulnerable who sequentially make up our society.</Subtitle>
                <Subtitle darkText={darkText}>Rebatho is purposed with a mandate to impact communities and serve people who seek freedom from slavery and exploitation that arises in different forms which could lead to human trafficking such as child labour or labour trafficking, sexual slavery, underage marriages or mutilation of body parts, and we call for justice to be served upon this injustice.</Subtitle>
                <Subtitle darkText={darkText}>We plan and participate in awareness campaigns – campaigns that inform and add value to communities that make up our society and the general public. We also have outreaches in both the city and rural development areas, where there is a high vulnerability rate for possible human trafficking activities.</Subtitle>
                <BtnWrap>
                  <Button
                    to="contactus"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                    primary={primary ? 1 : 0}
                    dark={dark ? 1 : 0}
                  >
                    {buttonLabel}
                  </Button>
                </BtnWrap>
              </TextWrapper>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
