import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import ScrollTopTop from "../components/ScrollToTop";
import Volunteer from "../components/Volunteer/Volunteer";

const OrderPage = ({match}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const menuItems = [{
    id: '/',
    title: 'About',
  },{
    id: 'services',
    title: 'Services',
  },{
    id: 'pricing',
    title: 'Price',
  },{
    id: 'contactus',
    title: 'Contact',
  }]

  useEffect(() => {
  }, [match])

  return (
    <>
        <ScrollTopTop />
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle} menu={menuItems} />
        <Volunteer />
        <Footer />
    </>
  );
};

export default OrderPage;
