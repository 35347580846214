import React, { useEffect } from 'react'

import { IconContext } from 'react-icons/lib'

import { GiPayMoney } from 'react-icons/gi'


import { FaPeopleCarry } from 'react-icons/fa'

import { Link, useHistory } from "react-router-dom"

import Products from '../../Products'

import { 
    OptionsSection,
    OptionsWrapper,
    OptionsHeader,
    OptionsContainer,
    OptionsCard,
    OptionsCardInfo,
    OptionsCardIcon,
    OptionsCardPlan,
    OptionsCardFeatures,
    OptionsCardFeature,
    Button,
    Subtitle,
} from './OptionsElements'

const Options = () => {

    let history = useHistory()

    useEffect(() => {
    }, [ history])

    return (
        <IconContext.Provider value={{ color: '#a9b3c1', size: 64 }}>
            <OptionsSection id='pricing'>
                <OptionsWrapper>
                    <OptionsHeader>Get Involved</OptionsHeader>
                    <Subtitle>
                        We furthermore build networks with similar industry organisations; 
                        hold school presentations; safe house assistance; 
                        Victim empowerment and associate with relevant government departments in order to achieve our goal.
                    </Subtitle>
                    <OptionsContainer>
                        {Products.map((product, index) => (
                            <OptionsCard key={index}>
                                <OptionsCardInfo>
                                    <OptionsCardIcon>
                                        <GiPayMoney style={{display: product.id === 1 ? 'block' : 'none', color: '#CCB23A'}} />
                                        <FaPeopleCarry style={{display: product.id === 2 ? 'block' : 'none', color: '#CCB23A'}} />
                                    </OptionsCardIcon>
                                    <OptionsCardPlan>{product.name}</OptionsCardPlan>
                                    
                                    <OptionsCardFeatures>
                                        {product.items.map((item, id) => (
                                            <OptionsCardFeature key={id}>{item.name}</OptionsCardFeature>
                                        ))}
                                    </OptionsCardFeatures>
                                    <Link to={product.link}>
                                        <Button primary type='submit'>{product.btnTitle}</Button>
                                    </Link>
                                </OptionsCardInfo>
                            </OptionsCard>
                            
                        ))}
                    </OptionsContainer>
                </OptionsWrapper>
            </OptionsSection>
        </IconContext.Provider>
    )
}

export default Options
