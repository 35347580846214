import {
    VOLUNTEER_REQUEST,
    VOLUNTEER_SUCCESS,
    VOLUNTEER_FAIL,
} from '../constants/volunteerConstants';


export const volunteerReducer = (state = {}, action) => {
    switch (action.type) {
        case VOLUNTEER_REQUEST:
            return { loading: true}
        case VOLUNTEER_SUCCESS:
            return { loading: false, volunteer: action.payload}
        case VOLUNTEER_FAIL:
            return { loading: false, error: action.payload }    
        default:
            return state
    }
}