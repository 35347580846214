export const homeAboutObj = {
    id: 'about',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Who we are',
    headline: 'About Us',
    description: 'An organisation established to abolish, furthermore positively and effectively rule over modern day slavery, this vile that poses an infringement of basic human rights, stripping away human dignity therefore instilling fear on victims and families and those vulnerable who sequentially make up our society.',
    buttonLabel: 'Contact Us',
    imgStart: true,
    img: require('../../images/hostage.jpg').default,
    alt: 'Car',
    dark: false,
    primary: false,
    darkText: true
}

export const homeObjContact = {
    id: 'contactus',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: "Start your journey.",
    headline: 'Contact Us',
    description: 'Start your journey with us.',
    buttonLabel: 'Learn More',
    imgStart: true,
    img: require('../../images/svg-2.svg').default,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
}

export const homeObjThree = {
    id: 'signup',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Premium Bank',
    headline: 'Easy setup',
    description: 'Get everything set up and ready in under 24 hours. All you need to do is contact us, and we can take if from there',
    buttonLabel: 'Start Now',
    imgStart: false,
    img: require('../../images/svg-3.svg').default,
    alt: 'Car',
    dark: false,
    primary: false,
    darkText: true
}

export const homeCoverObj = {
    id: 'signup',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Premium Bank',
    headline: 'Easy setup',
    description: 'Get everything set up and ready in under 24 hours. All you need to do is contact us, and we can take if from there',
    buttonLabel: 'Start Now',
    imgStart: false,
    img: require('../../images/Rebatho_cover.jpg').default,
    alt: 'Car',
    dark: false,
    primary: false,
    darkText: true
}

export const homeCoverData = {
    id: 'homecover',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Connect your business',
    headline: 'Customized Online Businesses Solutions For You...',
    description: 'Ready for the journey?',
    buttonLabel: "Get In Touch",
    imgStart: false,
    img: require('../../images/mobile.svg').default,
    alt: 'Car',
    dark: false,
    primary: true,
    darkText: false
}