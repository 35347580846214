const Products = [
    {
        id: 1,
        name: 'Donate',
        link: '/donate',
        type: 'once off',
        btnTitle: 'I Want To Donate',
        items: [{
                id: 1,
                name: 'Rebatho is an organisation that relies on donations. There are different ways you can donate and every Rand does make a difference.'   
            }
        ]
    },
    {
        id: 2,
        name: 'Volunteer',
        link: '/volunteer',
        type: 'per month',
        btnTitle: 'I Want To Volunteer',
        items: [{
            id: 1,
            name: 'Volunteer today to join a group of passionate people, at the forefront of saving raising awareness around human trafficking and creating lasting change!'   
        }
    ]
    }
]

export default Products