import React from "react";

import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesH2,
  ServicesP,
} from "./ServicesElements";

const Services = () => {
  return (
    <ServicesContainer id="services">
      <ServicesH1>What We Do</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesH2>Vission</ServicesH2>
          <ServicesP>
          Our vision is to positively and effectively rule over modern day slavery, 
          serving the people who make up our communities, being governed by principles of integrity and righteousness,
           impacting our country, continent and the world for the better
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesH2>Mission</ServicesH2>
          <ServicesP>
          Our mission is to be a door of hope for victims of human trafficking, 
          those whose rights have been infringed upon by this demeaning act,
           in ministering to their emotional, spiritual and physical well-being
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
