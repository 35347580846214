import styled from 'styled-components'
import { Link as LinkS } from 'react-scroll'
import { Link as LinkR } from 'react-router-dom'
import { FaTimes } from 'react-icons/fa'

export const SidebarContainer = styled.aside `
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: #581845;
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
    top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
`

export const Icon = styled.div `
    position: absolute;
    top: 1.5rem;
    left: 85%
`

export const CloseIcon = styled(FaTimes) `
    color: #F9F9F9;
    font-size: 24px;
`

export const SidebarWrapper = styled.div `
    color: #F9F9F9;
`

export const SidebarMenu = styled.div `
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 80px);
    text-align: center;

    @media screen and (max-width: 480px) {
        grid-template-rows: repeat(6, 60px);
    }
`

export const SidebarLink = styled(LinkS) `
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: #F9F9F9;
    cursor: pointer;

    &:hover {
        color: #3871ff;
        transition: 0.2s ease-in-out;
    }
`

export const SidebarLinks = styled(LinkR) `
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    text-decoration: none;
    color: #F9F9F9;
    cursor: pointer;

    &:hover {
        color: #3871ff;
        transition: 0.2s ease-in-out;
    }
`

export const SideBtnWrap = styled.div `
    display: flex;
    justify-content: center;
`

export const SidebarRoute = styled(LinkR) `
    border-radius: 50px;
    background: #3871ff;
    white-space: nowrap;
    padding: 16px 64px;
    color: #010606;
    font-size: 16px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: #F9F9F9;
        color: #010606;
    }
`

