import styled from "styled-components";

export const OptionsSection = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 100px 0 160px;
    background: #f9f9f9;
`;

export const OptionsWrapper = styled.div `
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
`

export const OptionsHeader = styled.h1 `
    margin-bottom: 24px;
    font-size: 48px;
    font-weight: 600;
    color: #283346;
    font-family: 'Architects Daughter', cursive;

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`

export const Subtitle = styled.p`
  max-width: 800px;
  margin-bottom: 35px;
  font-size: 16px;
  line-height: 20px;
  color: #283346;
  text-align: center;
`;

export const OptionsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 960px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
`

export const OptionsCard = styled.div`
    background: #581845;
    box-shadow: 0 6px 20px rgba(40, 51, 70, 1);
    width: 330px;
    height: 500px;
    text-decoration: none;
    border-radius: 4px;

    &:nth-child(2) {
        margin: 24px;
    }

    &:hover {
        transform: scale(1.06);
        transition: all 0.3s ease-out;
        color: #1c2237;
    }

    @media screen and (max-width: 960px) {
        width: 90%;

        &:hover {
            transform: none;
        }
    }

`

export const OptionsCardInfo = styled.div`
    display: flex;
    flex-direction: column;
    height: 50px;
    padding: 24px;
    align-items: center;
    color: #F9F9F9;
`

export const OptionsCardIcon = styled.p`
    margin: 24px 0;
`

export const OptionsCardPlan = styled.h3`
    margin-bottom: 3px;
    font-size: 30px;
    font-family: 'Architects Daughter', cursive;
`

export const OptionsCardCost = styled.h4`
    font-size: 40px;
`

export const OptionsCardLength = styled.p`
    font-size: 14px;
    margin-bottom: 24px;
`

export const OptionsCardFeatures = styled.ul`
    margin: 32px 0 32px;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #a9b3c1;
`

export const OptionsCardFeature = styled.li`
    margin-bottom: 10px;
    text-align: center;
`

export const Button = styled.button`
    border-radius: 4px;
    background: #CCB23A;
    white-space: nowrap;
    padding:  16px 64px;
    color: #F9F9F9;
    font-size: 14px;
    outline: none;
    border: none;
    cursor: pointer;

    &:hover {
        transition: all 0.3s ease-out;
        background: #F9F9F9;
        background: ${({ primary }) => (primary ? '#3871ff' : '#4b59f7')};
    }

    @media screen and (max-width: 960px) {
        width: 100%;
    }
`


export const Form = styled.form`
    margin: 0;
    padding: 0;
`;

export const FormInput = styled.input ` 
    margin: 0;
`