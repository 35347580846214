import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from "react-router-dom"

import { useAlert } from 'react-alert'

import { volunteer } from '../../actions/volunteerAction'


import {
  Container,
  FormWrap,
  FormContent,
  Form,
  FormH1,
  FormLabel,
  FormInput,
  FormInputTextArea,
  TopLine,
  FormButton,
  PhoneNumberFormInputError
} from "./VolunteerElements";


function Volunteer() {

  let history = useHistory()

  const alert = useAlert()

  const volunteerData = useSelector(state => state.volunteer)
  const { volunteer: volunteerSuccess } = volunteerData
  //states
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [age, setAge] = useState('')
  const [occupation, setOccupation] = useState('')
  const [description, setDescription] = useState('')
  const [message, setMessage] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {

    if(volunteerSuccess) {
      alert.success('Thank you for volunteering. We will contact you')
    }
    
  }, [history, volunteerSuccess, alert])

  const handleSubmit = (e) => {
    e.preventDefault()

    var numbers = /^[+27]?[0-9]+$/;

    if(phone.includes("+27")){
        let cleanNumber = phone.replace("+27", "0")
        setPhone(cleanNumber)
        setMessage('')
    }
    
    if(phone.length !== 10 || !phone.match(numbers)) {
      setMessage('Incorrect number format')
    }else{
      setMessage('')
    }

    if (isNaN(phone)) {
        setMessage('Incorrect Phone Number. Please enter the correct numbers')
    }

    if(message === '') {
      dispatch(volunteer(name, phone, email, age, occupation, description))
      setName('')
      setPhone('')
      setEmail('')
      setAge('')
      setOccupation('')
      setDescription('')
    }

  }



  return (
    <>
      <Container>
        <FormWrap>
          <FormContent>
            <Form onSubmit={handleSubmit}>
              <TopLine>Your help is needed</TopLine>
              <FormH1>Volunteer</FormH1>
              <FormLabel htmlFor="name">Name</FormLabel>
              <FormInput 
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required />
              <FormLabel htmlFor="phone">Phone Number</FormLabel>
              <FormInput 
                type="text"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required />
                {message && (
                  <PhoneNumberFormInputError>
                    {message}
                  </PhoneNumberFormInputError>
                )}
              <FormLabel htmlFor="email">Email</FormLabel>
              <FormInput 
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required />
                <FormLabel htmlFor="age">Age</FormLabel>
                <FormInput 
                  type="number"
                  name="age"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                  required />
                <FormLabel htmlFor="occupation">Occupation</FormLabel>
                <FormInput 
                  type="text"
                  name="occupation"
                  value={occupation}
                  onChange={(e) => setOccupation(e.target.value)}
                  required />
                <FormLabel htmlFor="description">Description</FormLabel>
                <FormInputTextArea 
                  type="text"
                  name="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  required />
              <FormButton type="submit">Volunteer</FormButton>
            </Form>
          </FormContent>
        </FormWrap>
      </Container>
    </>
  )
}

export default Volunteer
